import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  AnonymousAvatarComponent as AnonymousAvatarIcon,
} from 'ui/Icons';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { theme } from '../../../../tailwind.config';
import { ITaskComment } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { formatDate } from 'utils';
import { EUserType, IUser } from 'services/BackendApi';

const userTypeMapping = {
  [EUserType.TA]: 'Travel Agent',
  [EUserType.SR]: 'Sales Rep.',
  [EUserType.FINANCE]: 'Finance',
  [EUserType.RL]: 'Rate Loader',
  [EUserType.ADMIN]: 'Admin',
};

interface ITaskCommentViewProps {
  comment: ITaskComment;
}

export const TaskCommentView: React.FC<ITaskCommentViewProps> = ({ comment }) => {
  // setting to true until "edit comments" functionality implemented 
  const canEdit = true;
  
  const author = [comment.createdBy.firstName, comment.createdBy.lastName]
    .filter(Boolean)
    .join(' ');
  const date = formatDate(comment.createdAt, 'd MMM yyyy, hh:mm');
  const role = userTypeMapping[comment.createdBy.type];
  const loggedInUser = useSelector(AuthSelectors.getCurrentUser) as IUser;
  const isYou = comment.createdBy.id === loggedInUser.uuid;

  const handleEdit = useCallback(() => alert('Not implemented yet'), []);
  const handleDelete = useCallback(() => alert('Not implemented yet'), []);
  
  return (
    <div className="view-comment flex  items-start gap-[10px]">
      <div className="user-picture">
        <AnonymousAvatarIcon icon={theme.colors['gray-100']} fill={theme.colors['gray-40']} />
      </div>
      <div className="comment-container">
        <p className="comment-title font-pt-sans text-gray-100 m-0 text-[12px]">
          {!isYou && <span className="comment-author font-bold capitalize">{author}</span>}
          {!isYou && <span className="author-role">&nbsp;({role})</span>}
          {isYou && <span className="comment-author font-bold capitalize">You</span>}
          ,&nbsp;<span className="comment-created-date">{date}</span>
          {canEdit && <span className="comment-edit text-brown-100 underline capitalize cursor-pointer ml-[5px]" onClick={handleEdit}>Edit</span>}
          {canEdit && <span className="comment-edit text-brown-100 underline capitalize cursor-pointer ml-[5px]" onClick={handleDelete}>Delete</span>}
        </p>
        {comment.content.split(/\n/).map(item => (
          <p className="comment-text font-pt-sans text-black text-[15px] leading-[22px] m-0 min-h-[22px]">
          {item}
        </p>
        ))}
      </div>
    </div>
  )
};
